import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import PageTitle from "../../components/page-title"
import Constrain from "../../components/constrain"
import Paragraph from "../../components/paragraph"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import VideoPlayer from "../../components/video-player"

const Katalog3 = () => {
  const data = useStaticQuery(graphql`
    query {
      poster: file(
        relativePath: { eq: "videos/poster-katalog-wanderndewaende.jpg" }
      ) {
        ...largeImage
      }
    }
  `)
  return (
    <Layout backdrop="aussen">
      <Seo title="Wandernde Wände" />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Wandernde Wände",
                link: "/k/3",
              },
            ]}
          />
          <PageTitle>Wandernde Wände</PageTitle>
          <Constrain align="left">
            <Paragraph>Veränderungen am Gebäude 1778–2020</Paragraph>
          </Constrain>
          <VideoPlayer src="katalog/03-wanderndewaende" poster={data.poster} />
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Katalog3
